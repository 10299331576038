@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';

.newsletter-block {
	background-color: colors.$grey-100;
	border-radius: 20px;

	&__wrapper {
		padding: 3rem;
	}

	&__form {
		display: grid;
		grid-template-columns: minmax(1rem, 100%);
		grid-template-areas: 'newsletter-label' 'newsletter-email' 'newsletter-submit';
		grid-gap: 2rem;
	}

	&__label {
		grid-area: newsletter-label;
		color: colors.$grey-400;
		text-transform: uppercase;
		font-weight: 900;
		@extend %font-xl;
	}

	.newsletter-block__email {
		grid-area: newsletter-email;
		padding: 1.35rem 2.6rem;
		border-radius: 100px;
		border-bottom: none;
	}

	.newsletter-block__submit {
		grid-area: newsletter-submit;
		color: colors.$white;
		cursor: pointer;

		&::before {
			background-color: colors.$orange;
			border-color: colors.$orange;
		}

		&:hover {
			color: colors.$orange;

			i {
				color: colors.$white;
			}
		}
	}
}

@media screen and (min-width: structure.$tablet-landscape) {
	.newsletter-block__form {
		grid-template-columns: repeat(2, minmax(1rem, 1fr));
		grid-column-gap: 3rem;
		grid-template-areas: 'newsletter-label newsletter-email' 'newsletter-label newsletter-submit';
		grid-row-gap: 2rem;
	}
}
